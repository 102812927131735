import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import { Button, ExtLinks } from '../components/elements'

// import ml5 from "ml5"

const tunerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hotel: file(relativePath: { regex: "/hotel/" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="React Instrument Tuner"
        keywords={['Instrument Tuner ReactJS, ml5 tuner, ReactJS ML5 Tuner']}
      />

      <InfoPagesWrapper>
        <h1>A ReactJS and Machine Learning Instrument Tuner</h1>
        <p>
          While it is not pretty (yet!), it was a nice challenge to get this
          working. Here I am asking for your microphone input from your
          computer, listening for frequencies (notes), and outputting how in
          tune you are.
        </p>
        <p>
          This uses a machine learning pitch detection algorithm called{' '}
          <a href="https://ml5js.org/">ml5</a> along with ReactJS &{' '}
          <a href="https://www.framer.com/motion/">Framer Motion</a> (which is the animation library this site is
          using by the way).
        </p>
        <ExtLinks
          css={`
            margin-bottom: 3rem !important;
          `}
          href="https://tommytunes.netlify.app"
          target="_blank"
          rel="noreferrer"
        >
          Try it out!
        </ExtLinks>
        <ExtLinks
          href="https://github.com/tcaraher/react-ml5-tuner"
          target="_blank"
          rel="noreferrer"
        >
          Check out the code
        </ExtLinks>
      </InfoPagesWrapper>
    </Layout>
  )
}

export default tunerPage
